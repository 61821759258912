var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-2",
          attrs: { tile: "", flat: "", color: "grey lighten-3" },
        },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "grey lighten-3" } },
            [
              _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("mdi-clipboard-text"),
              ]),
              _c("v-toolbar-title", [_vm._v("Order Entry")]),
              _c("v-divider", {
                staticClass: "mx-4 d-none d-sm-flex",
                attrs: { inset: "", vertical: "" },
              }),
              _c(
                "v-col",
                {
                  staticClass: "text-truncate",
                  staticStyle: { "max-width": "430px" },
                  attrs: { cols: "auto", lg: "3" },
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      label: "Store",
                      items: _vm.stores,
                      loading: _vm.loading,
                      "item-text": "name",
                      "item-value": "id",
                      outlined: "",
                      dense: "",
                      "background-color": "input",
                      "hide-details": "auto",
                      "prepend-inner-icon": "mdi-store-outline",
                    },
                    on: {
                      "update:items": function ($event) {
                        _vm.stores = $event
                      },
                      change: _vm.handleChangedStore,
                    },
                    model: {
                      value: _vm.storeId,
                      callback: function ($$v) {
                        _vm.storeId = $$v
                      },
                      expression: "storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "d-none d-sm-flex text-truncate",
                  staticStyle: { "max-width": "430px" },
                  attrs: { lg: "4" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Customer Invoice Note (Optional)",
                      dense: "",
                      "validate-on-blur": "",
                      "background-color": "input",
                      outlined: "",
                      maxlength: "14",
                      "hide-details": "auto",
                    },
                    on: {
                      keyup: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleNoteInput(_vm.note)
                      },
                    },
                    model: {
                      value: _vm.note,
                      callback: function ($$v) {
                        _vm.note = $$v
                      },
                      expression: "note",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "d-flex d-sm-none", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { staticClass: "px-4", attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-truncate" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Customer Invoice Note (Optional)",
                          dense: "",
                          "validate-on-blur": "",
                          "background-color": "input",
                          outlined: "",
                          maxlength: "14",
                          "hide-details": "auto",
                        },
                        on: {
                          keyup: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleNoteInput(_vm.note)
                          },
                        },
                        model: {
                          value: _vm.note,
                          callback: function ($$v) {
                            _vm.note = $$v
                          },
                          expression: "note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticStyle: { "border-top": "1px solid #ddd" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-row",
            { staticClass: "px-2", attrs: { dense: "", align: "center" } },
            [
              _c(
                "v-col",
                { staticStyle: { "max-width": "430px" } },
                [
                  !_vm.loading
                    ? _c("ItemSearchFilters", {
                        attrs: { loadingFilters: _vm.loadingFilters },
                        on: { handleFilterInput: _vm.handleFilterInput },
                        model: {
                          value: _vm.searchParams,
                          callback: function ($$v) {
                            _vm.searchParams = $$v
                          },
                          expression: "searchParams",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "ml-auto d-none d-sm-flex",
                  attrs: { cols: "auto" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "font-weight-medium text-no-wrap text-h6 my-auto",
                    },
                    [_vm._v(" Est. Total: $" + _vm._s(_vm.totalCost) + " ")]
                  ),
                ]
              ),
              _c("v-divider", {
                staticClass: "mx-4 mb-2 d-none d-sm-flex",
                attrs: { inset: "", vertical: "" },
              }),
              _c(
                "v-col",
                { staticClass: "d-flex d-sm-none", attrs: { cols: "auto" } },
                [
                  _c(
                    "p",
                    { staticClass: "font-weight-medium text-no-wrap my-auto" },
                    [_vm._v(" Est. Total: $" + _vm._s(_vm.totalCost) + " ")]
                  ),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto", "align-self": "end" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-none",
                      attrs: {
                        color: "main",
                        rounded: "",
                        text: "",
                        readonly: _vm.loading,
                        disabled: _vm.orderingDisabled || !_vm.activeOrder,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.cartOpen = !_vm.cartOpen
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-cart"),
                      ]),
                      _vm._v(" Cart "),
                      _c("v-badge", {
                        staticClass: "ml-2 mb-2",
                        attrs: {
                          color: "primary",
                          content: _vm.cartCount,
                          value: _vm.cartCount > 0,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { tile: "", flat: "" } },
        [
          _c("ItemSearchTable", {
            attrs: {
              storeSources: _vm.storeSources,
              orderItems: _vm.orderItems,
              catalogName: _vm.catalogName,
              searchParams: _vm.searchParams,
              storeId: _vm.storeId,
              orderLoading: _vm.loading,
              showActiveAllowances: _vm.showActiveAllowances,
            },
            on: { add: _vm.addItem },
          }),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-navigation-drawer",
                {
                  staticStyle: { "z-index": "20" },
                  attrs: {
                    absolute: "",
                    right: "",
                    bottom: "",
                    width: _vm.cartOpen ? "800px" : "0",
                    temporary: "",
                    color: "grey lighten-3",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "append",
                      fn: function () {
                        return [
                          _c(
                            "v-row",
                            { staticClass: "d-flex px-4" },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-4", attrs: { cols: "4" } },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "font-weight-medium text-no-wrap text-h6 pb-2",
                                    },
                                    [
                                      _vm._v(
                                        " Est. Order Total: $" +
                                          _vm._s(_vm.totalCost) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-row",
                                { staticClass: "mt-6 mr-6" },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "max-width": "290px",
                                        "min-width": "290px",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      ref: "scheduledSubmitDate",
                                                      staticClass: "mr-4",
                                                      attrs: {
                                                        label:
                                                          "Scheduled Submit Date",
                                                        type: "date",
                                                        "prepend-inner-icon":
                                                          "mdi-calendar",
                                                        dense: "",
                                                        "background-color":
                                                          "#fff",
                                                        outlined: "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.scheduledSubmitDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.scheduledSubmitDate =
                                                            $$v
                                                        },
                                                        expression:
                                                          "scheduledSubmitDate",
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.date_picker,
                                        callback: function ($$v) {
                                          _vm.date_picker = $$v
                                        },
                                        expression: "date_picker",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: {
                                          min: new Date(
                                            Date.now() + 3600 * 1000 * 24
                                          )
                                            .toISOString()
                                            .substring(0, 10),
                                          "no-title": "",
                                        },
                                        model: {
                                          value: _vm.scheduledSubmitDate,
                                          callback: function ($$v) {
                                            _vm.scheduledSubmitDate = $$v
                                          },
                                          expression: "scheduledSubmitDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-auto white--text",
                                      attrs: {
                                        color: _vm.scheduledSubmitDate
                                          ? "primary"
                                          : "success",
                                        loading: _vm.loading,
                                        disabled:
                                          _vm.orderItems.length === 0 ||
                                          _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.scheduledSubmitDate
                                            ? (_vm.confirmSchedule = true)
                                            : (_vm.confirmSubmit = true)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.scheduledSubmitDate
                                              ? "Schedule Order"
                                              : "Submit Order"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-4",
                                      attrs: {
                                        disabled: !_vm.order.id || _vm.loading,
                                        outlined: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.confirmDelete = true
                                        },
                                      },
                                    },
                                    [_vm._v(" Clear ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.cartOpen,
                    callback: function ($$v) {
                      _vm.cartOpen = $$v
                    },
                    expression: "cartOpen",
                  },
                },
                [
                  _c("OrderItemsTable", {
                    attrs: {
                      orderItems: _vm.orderItems,
                      orderLoading: _vm.loading,
                      selectedStore: this.selectedStore,
                    },
                    on: {
                      updateItem: _vm.updateItem,
                      removeItem: _vm.removeItem,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.confirmSubmit
        ? _c("SubmitOrderDialog", {
            attrs: { submitting: _vm.submitting },
            on: { cancel: _vm.cancelSubmit, confirmSubmit: _vm.submitOrder },
            model: {
              value: _vm.confirmSubmit,
              callback: function ($$v) {
                _vm.confirmSubmit = $$v
              },
              expression: "confirmSubmit",
            },
          })
        : _vm._e(),
      _vm.confirmSchedule
        ? _c("ScheduleOrderDialog", {
            attrs: {
              submitting: _vm.submitting,
              scheduledSubmitDate: _vm.scheduledSubmitDate,
            },
            on: {
              cancel: _vm.cancelSchedule,
              confirmSchedule: _vm.scheduleOrder,
            },
            model: {
              value: _vm.confirmSchedule,
              callback: function ($$v) {
                _vm.confirmSchedule = $$v
              },
              expression: "confirmSchedule",
            },
          })
        : _vm._e(),
      _vm.confirmDelete
        ? _c("ConfirmDialog", {
            attrs: {
              title: "Clear Order",
              text: "Clear the current order? This will clear all items.",
            },
            on: { cancel: _vm.cancelDelete, confirm: _vm.clearOrder },
            model: {
              value: _vm.confirmDelete,
              callback: function ($$v) {
                _vm.confirmDelete = $$v
              },
              expression: "confirmDelete",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }