<template>
  <v-select
    v-model="selectedFilters"
    label="Search Filters"
    :items="filters"
    :disabled="disabled"
    item-text="name"
    item-value="key"
    background-color="input"
    :menu-props="{ offsetY: true, maxHeight: '600' }"
    multiple
    outlined
    hide-details
    small-chips
    dense>
    <template v-slot:selection="{ attrs, item }">
      <template v-if="item.key === 'item_sources' && (params.item_sources.length > 0)">
        <v-chip
          v-for="source in selectedSources"
          :key="source.source_id"
          small>
          {{ source.display_name }}
          <v-icon
            small
            right
            @click="removeSource(source)">
            $delete
          </v-icon>
        </v-chip>
      </template>
      <v-chip
        v-else
        v-bind="attrs"
        small>
        {{ getFilterText(item) }}
        <v-icon
          small
          right
          @click="removeFilter(item.key, true)">
          $delete
        </v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ item, attrs }">
      <v-list-item>
        <v-list-item-content>
          <v-autocomplete
            v-if="item.key === 'item_sources'"
            v-model="selectedSources"
            label="Item Sources"
            :items="userSources"
            item-text="display_name"
            item-value="source_id"
            multiple
            deletable-chips
            small-chips
            dense
            outlined
            hide-details
            return-object
            clearable>
          </v-autocomplete>
          <v-autocomplete 
            v-else-if="['category','department'].includes(item.key)"
            v-model="params[`${item.key}`]"
            :items="keyItemMap[item.key]"
            :label="item.name"
            dense
            hide-details
            :loading="loadingFilters"
            outlined
            clearable
            @click:clear="removeFilter(item.key)"
            @change="handleInputValue(item)">
          </v-autocomplete>
          <v-text-field
            v-else
            v-model="params[`${item.key}`]"
            :label="item.name"
            background-color="input"
            :prefix="item.prefix || null"
            :type="item.type"
            dense
            clearable
            hide-details
            @input="attrs.inputValue ? null : handleInputValue(item)"
            @click:clear="removeFilter(item.key)"
            @keydown.enter="updateSearchFilters(true)"
            outlined>
          </v-text-field>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
// mixins
import { userAccess } from '@/mixins/user-access'
// third-party
import { debounce } from 'lodash'
export default {
  data () {
    return {
      selectedFilters: [],
      selectedSources: [],
      params: {}
    }
  },
  name: 'SearchFilters',
  mixins: [userAccess],
  props: {
    value: Object,
    disabled: Boolean,
    loadingFilters: Boolean
  },
  watch: {
    value: {
      handler (newValue) {
        if (Object.keys(newValue).length === 0) {
          this.selectedFilters = []
          this.params = {} 
        }
      },
      deep: true
    },
    activeFilters: {
      handler: debounce(function () {
          this.updateSearchFilters()
      }, 400),
      deep: true
    },
    selectedSources: {
      handler (newValue) {
        if (newValue) {
          if (newValue.length > 0) {
            sessionStorage.setItem('source_filters', JSON.stringify(newValue))
          } else {
            sessionStorage.removeItem('source_filters')
          }
          this.handleChangedSources(newValue, 'item_sources')
        }
      },
      deep: true
    }
  },
  async created () {
    if (this.value) {
      this.params = { ...this.value }
      Object.keys(this.params).map((k) => {
        this.selectedFilters.push(k)
      })
    }
  },
  computed: {
    filters () {
      return [
        // { name: 'Sources', key: 'item_sources', show: true },
        { name: 'Description', key: 'description', type: 'text', show: true },
        { name: 'Item Id', key: 'item_id', type: 'text', show: true },
        { name: 'UPC', key: 'upc', type: 'text', show: true },
        { name: 'Department', text: 'Dept', key: 'department', type: 'text', show: true },
        { name: 'Category', key: 'category', type: 'text', show: true },
        { name: 'Subcategory', key: 'subcategory', type: 'text', show: true }
      ].filter(item => item.show)
    },
    activeFilters () {
      return Object.keys(this.params).filter(this.isActive)
    },
    departments () {
      const departments = this.$store.getters.departments
      return departments.filter(item => item !== 'LIQUOR WAREHOUSE')
    },
    categories () {
      const categories = this.$store.getters.categories
      return categories.filter(item => !['BEER', 'WINE', 'SPIRITS'].includes(item))
    },
    keyItemMap () {
      return {'category': this.categories, 'department': this.departments}
    }
  },
  methods: {
    isActive (key) {
      if (this.isSelected(key) && Boolean(this.params[key])) {
        const value = this.params[key]
        return Array.isArray(value) ? (value.length > 0) : true
      }
      return false
    },
    getFilterText (item) {
      return `${item.text || item.name}: "${this.params[item.key]}"`
    },
    removeFilter (key, clearValue = false) {
      const index = this.selectedFilters.indexOf(key)
      if (index > -1) {
        this.selectedFilters.splice(index, 1)
        if (clearValue) this.params[key] = null
      }
      if (this.selectedFilters.length === 0) {
        this.updateSearchFilters()
      }
    },
    removeSource (source) {
      const index = this.selectedSources.findIndex(v => v === source)
      if (index > -1) {
        this.selectedSources.splice(index, 1)
      }
    },
    handleInputValue: debounce(function (item) {
      if (Boolean(this.params[item.key]) && !this.isSelected(item.key)) {
        this.selectedFilters.push(item.key)
      }
    }, 200),
    handleChangedSources (newValue, key) {
      this.params.item_sources = newValue.flatMap(v => v ? v.source_id : [])
      if ((newValue.length > 0) && !this.isSelected(key)) {
        this.selectedFilters.push(key)
      } else if ((newValue.length === 0) && this.isSelected(key)) {
        this.removeFilter(key)
      }
    },
    isSelected (key) {
      return this.selectedFilters.includes(key)
    },
    updateSearchFilters () {
      const filtersObj = this.activeFilters.reduce((obj, key) => {
        obj[key] = this.params[key]
        return obj
      }, {})
      if (this.activeFilters.length > 0) {
        sessionStorage.setItem('order_item_filters', JSON.stringify(filtersObj))
      } else {
        sessionStorage.removeItem('order_item_filters')
      }
      this.$emit('handleFilterInput', filtersObj)
    }
  }
}
</script>