<template>
  <v-container fluid class="ma-0 pa-0">
    <v-data-table
      :height="tableSize" 
      :headers="computedHeaders" 
      :items="items"
      :loading="loading" 
      item-key="uuid" 
      hide-default-footer 
      :items-per-page.sync="pageSize"
      no-data-text="Please enter search criteria"
      fixed-header
      dense>

      <template v-slot:[`item.sale_price`]="{ item }">
        <td class="text-no-wrap">
          {{ item.sale_price == 0 ? '-' : `${item.sale_price ? '$' + item.sale_price.toFixed(2) : '-'}` }}
        </td>
      </template>
      <template v-slot:[`item.cost`]="{ item }">
        <td class="text-no-wrap">{{ item.cost ? '$' + item.cost.toFixed(2) : '-' }}</td>
      </template>

      <template v-slot:[`item.history`]="{ item }">
        <v-btn icon color="primary" @click.stop="getOrderHistory(item)">
          <v-icon>mdi-history</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.add`]="{ item }">
        <v-btn
          :disabled="addingItem || isOrderItem(item)" 
          icon 
          color="success" 
          @click.stop="add(item)">
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.active_allowances`]="{ item }">
        <v-tooltip class="mx-auto" top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              small
              color="primary"
              :disabled="!item.active_oi_allowances"
              @click="openActiveAllowances(item)">
              <v-icon class="mx-auto">{{ item.active_oi_allowances ? 'mdi-tag' : 'mdi-tag-outline' }}</v-icon>
            </v-btn>
          </template>
          <span>View Deals</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <PagingFooter 
          :pageSizes="pageSizes" 
          :pageSize.sync="pageSize" 
          :page="page"
          :disablePrevious="disablePrevious" 
          :disableNext="disableNext" 
          @previous="previous" 
          @next="next">
        </PagingFooter>
      </template>
    </v-data-table>

    <OrderHistoryModal
      v-if="orderHistory"
      v-model="orderHistory"
      :item="historyItem"
      :party_id="storeId"
      @close="closeOrderHistory">
    </OrderHistoryModal>

    <ActiveAllowancesModal
      v-if="activeAllowancesModal"
      v-model="activeAllowancesModal"
      :selectedItem="activeAllowanceItem"
      @close="closeActiveAllowances">
    </ActiveAllowancesModal>
  </v-container>
</template>
<script>
// API
import Items from '@/api/item'
// Components
import PagingFooter from '@/components/PagingFooter.vue'
import OrderHistoryModal from '@/components/items/OrderHistoryModal.vue'
import ActiveAllowancesModal from '@/components/items/ActiveAllowancesModal.vue'
// Mixins
import { userAccess } from '@/mixins/user-access'
import { displayAlert } from '@/mixins/alert'
import { fullWidthTable } from '@/mixins/table'
export default {
  name: 'OrderItemSearch',
  data() {
    return {
      items: [],
      page: 1,
      pageSize: 50,
      loading: false,
      addingItem: false,
      orderHistory: false,
      historyItem: null,
      activeAllowanceItem: null,
      activeAllowancesModal: false
    }
  },
  props: {
    storeId: String,
    storeSources: Array,
    orderItems: Array,
    catalogName: String,
    searchParams: Object,
    orderLoading: Boolean,
    showActiveAllowances: Boolean
  },
  watch: {
    orderLoading: {
      handler(newValue) {
        if (newValue === false && this.addingItem) {
          this.addingItem = false
        }
      }
    },
    searchParams: {
      handler(newValue) {
        if (newValue) {
          this.handleFilterInput()
        }
      },
      deep: true
    },
    storeId: {
      handler(newValue, oldValue) {
        if (newValue && oldValue) this.searchItems()
      }
    },
    page: {
      handler(newValue) {
        if (newValue) {
          this.searchItems()
        }
      }
    },
     pageSize: {
      handler(newValue) {
        if (newValue) {
          if (this.page > 1) {
            return this.page = 1
          } else {
            this.searchItems()
          }
        }
      }
    },
  },
  mixins: [fullWidthTable, userAccess, displayAlert],
  components: { PagingFooter, OrderHistoryModal, ActiveAllowancesModal },
  mounted() {
    this.nonTableHeight = 299.3
  },
  computed: {
    headers () {
      return [
        // { text: 'Source', value: 'source', show: true },
        { text: 'Item ID', value: 'item_id', show: true },
        { text: 'Description', value: 'description', show: true },
        { text: 'UPC', value: 'upc', show: true },
        { text: 'Department', value: 'department', show: true },
        { text: 'Category', value: 'category', show: true },
        { text: 'Subcategory', value: 'subcategory', show: true },
        { text: 'Qty Per Case', value: 'qty_per_case', show: true },
        { text: 'Pack Size', value: 'pack_size', show: true },
        { text: 'SRP', value: 'sale_price', show: true },
        { text: 'Cost', value: 'cost', show: true },
        { text: 'History', value: 'history', show: true, sortable: false, align: 'center' },
        { text: 'Deals', value: 'active_allowances', show: this.showActiveAllowances, sortable: false, align: 'center' },
        { text: 'Add', value: 'add', show: true, sortable: false, align: 'center' }
      ].filter(h => h.show)
    },
    limit() {
      return this.pageSize
    },
    offset() {
      return (this.page - 1) * this.pageSize
    },
    disablePrevious() {
      return this.page === 1
    },
    disableNext() {
      return this.items.length < this.pageSize
    },
    computedHeaders() {
      return this.headers.flatMap(item => {
        return { ...item, class: 'accent white--text text-no-wrap' }
      })
    }
  },
  methods: {
    async searchItems() {
      if (!this.catalogName || Object.values(this.searchParams).length === 0) {
        return this.items = []
      }
      this.loading = true
      this.items = []
      let items = []
      try {
        const params = {
          sources: this.storeSources,
          catalog_name: this.catalogName,
          check_allowances: this.showActiveAllowances,
          ...this.searchParams
        }
        const { data } = await Items.searchItems(params, this.offset, this.limit, true, true, true)
        if (data.length > 0) {
          items = data.map(item => {
            if (item.active_allowances?.length > 0) {
              item.active_allowances = item.active_allowances.filter(aa => aa.status_constant === 'APPROVED' && aa.billing_mode === 'OI')
              if (item.active_allowances?.length > 0) {
                item.active_oi_allowances = true
              }
            }
            return item
          })
        }
        this.items = items
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    removeItem(item) {
      this.$emit('removeItem', item)
    },
    getOrderHistory(item) {
      this.historyItem = item
      this.orderHistory = true
    },
    closeOrderHistory() {
      this.orderHistory = false
    },
    openActiveAllowances (item) {
      this.activeAllowanceItem = item
      this.activeAllowancesModal = true
    },
    closeActiveAllowances () {
      this.activeAllowancesModal = false
    },
    add(item) {
      this.addingItem = true
      this.$emit('add', item)
    },
    isOrderItem(item) {
      return this.orderItems.some(orderItem => {
        return (orderItem?.item_id === item.item_id) && (orderItem?.source === item.source)
      })
    },
    next() {
      this.page++
    },
    previous() {
      this.page--
    },
    handleFilterInput () {
      return (this.page > 1) ? this.page = 1 : this.searchItems()
    }
  }
}
</script>
<style lang="scss" scoped>
.active {
  box-shadow: 0px 2px 10px 3px rgba(120,120,120,0.75);
  background: #385F73;
  color: white !important;
}
.theme--light.v-data-table .v-data-table-header th.sortable.accent.white--text .v-data-table-header__icon {
  color: #fff !important;
}
</style>