var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, persistent: "", width: "700" },
      on: {
        "click:outside": function ($event) {
          return _vm.close()
        },
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pl-5 pr-2 pt-4 pb-2" },
            [
              _c("div", { staticClass: "title h6" }, [
                _vm._v(
                  " Active Allowances - " +
                    _vm._s(_vm.selectedItem.description) +
                    " "
                ),
              ]),
              _c("br"),
              _c("v-spacer"),
              _c("v-card-subtitle", { staticClass: "pa-0 ma-0" }, [
                _vm._v(" Item ID: " + _vm._s(_vm.selectedItem.item_id) + " "),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.formattedAllowances,
                  "no-data-text": "No approved allowances",
                  dense: "",
                  height: _vm.tableHeight,
                  "fixed-header": "",
                  "disable-pagination": "",
                  "footer-props": { "items-per-page-options": [-1] },
                  "items-per-page": -1,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }