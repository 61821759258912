<template>
  <v-container fluid class="ma-0 pa-0">
    <!-- Top Section -->
    <v-card class="py-2" tile flat color="grey lighten-3">
      <v-toolbar flat color="grey lighten-3">
        <v-icon class="mr-2">mdi-clipboard-text</v-icon>
        <v-toolbar-title>Order Entry</v-toolbar-title>
        <v-divider class="mx-4 d-none d-sm-flex" inset vertical></v-divider>
        <v-col cols="auto" lg="3" style="max-width: 430px" class="text-truncate">
          <v-autocomplete
            v-model="storeId"
            label="Store"
            :items.sync="stores"
            :loading="loading"
            item-text="name"
            item-value="id"
            outlined
            dense
            background-color="input"
            hide-details="auto"
            prepend-inner-icon="mdi-store-outline"
            @change="handleChangedStore">
          </v-autocomplete>
        </v-col>
        <v-col lg="4" style="max-width: 430px" class="d-none d-sm-flex text-truncate">
          <v-text-field
            v-model="note"
            label="Customer Invoice Note (Optional)"
            dense
            validate-on-blur
            background-color="input"
            outlined
            maxlength="14"
            hide-details="auto"
            @keyup.stop="handleNoteInput(note)">
          </v-text-field>
        </v-col>
      </v-toolbar>
      <!-- note input below only visible on smallest screen size -->
      <v-container fluid class="d-flex d-sm-none">
        <v-row dense align="center" class="px-4">
          <v-col class="text-truncate">
            <v-text-field
              v-model="note"
              label="Customer Invoice Note (Optional)"
              dense
              validate-on-blur
              background-color="input"
              outlined
              maxlength="14"
              hide-details="auto"
              @keyup.stop="handleNoteInput(note)">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- Bottom Section (filters, item search table, cart) -->
    <v-container fluid style="border-top: 1px solid #ddd;">
      <v-row dense align="center" class="px-2">
        <v-col style="max-width: 430px">
          <ItemSearchFilters
            v-if="!loading"
            :loadingFilters="loadingFilters"
            v-model="searchParams"
            @handleFilterInput="handleFilterInput"
            >
          </ItemSearchFilters>
        </v-col>
        <v-col cols="auto" class="ml-auto d-none d-sm-flex">
          <p class="font-weight-medium text-no-wrap text-h6 my-auto">
            Est. Total: ${{ totalCost }}
          </p>
        </v-col>
        <v-divider class="mx-4 mb-2 d-none d-sm-flex" inset vertical></v-divider>
        <v-col cols="auto" class="d-flex d-sm-none">
          <p class="font-weight-medium text-no-wrap my-auto">
            Est. Total: ${{ totalCost }}
          </p>
        </v-col>
        <!-- Cart Icon -->
        <v-col cols="auto" align-self="end">
          <v-btn
            @click.stop="cartOpen = !cartOpen"
            class="text-none"
            color="main"
            rounded
            text
            :readonly="loading"
            :disabled="orderingDisabled || !activeOrder">
            <v-icon left>mdi-cart</v-icon>
            Cart
            <v-badge
              class="ml-2 mb-2"
              color="primary" 
              :content="cartCount" 
              :value="cartCount > 0">
            </v-badge>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-card tile flat>
      <!-- Item Search Table -->
      <ItemSearchTable
        :storeSources="storeSources"
        :orderItems="orderItems"
        :catalogName="catalogName"
        :searchParams="searchParams"
        :storeId="storeId"
        :orderLoading="loading"
        :showActiveAllowances="showActiveAllowances"
        @add="addItem">
      </ItemSearchTable>

      <!-- Order Cart Drawer -->
      <v-container fluid class="ma-0 pa-0">
        <v-navigation-drawer
          v-model="cartOpen"
          style="z-index: 20"
          absolute
          right
          bottom
          :width="cartOpen ? '800px' : '0'"
          temporary
          color="grey lighten-3">
          <!-- Cart Items -->
          <OrderItemsTable
            :orderItems="orderItems" 
            :orderLoading="loading"
            :selectedStore="this.selectedStore"
            @updateItem="updateItem" 
            @removeItem="removeItem">
          </OrderItemsTable>
          <!-- Cart Footer -->
          <template v-slot:append>
            <v-row class="d-flex px-4">
              <v-col cols="4" class="mt-4">
                  <p class="font-weight-medium text-no-wrap text-h6 pb-2">
                    Est. Order Total: ${{ totalCost }}
                  </p>
              </v-col>
              <v-row class="mt-6 mr-6">
                <v-menu
                  v-model="date_picker"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="scheduledSubmitDate"
                      label="Scheduled Submit Date"
                      ref="scheduledSubmitDate"
                      type="date"
                      class="mr-4"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      background-color="#fff"
                      outlined
                      clearable
                      v-on="on"
                      v-bind="attrs">
                    </v-text-field>
                  </template>
                  <v-date-picker 
                    v-model="scheduledSubmitDate"
                    :min="new Date(Date.now() + ( 3600 * 1000 * 24)).toISOString().substring(0, 10)"
                    no-title>
                  </v-date-picker>
                </v-menu>
                <v-btn
                  class="ml-auto white--text"
                  :color="scheduledSubmitDate ? 'primary':'success'"
                  :loading="loading"
                  :disabled="orderItems.length === 0 || loading"
                  @click="scheduledSubmitDate ? confirmSchedule = true : confirmSubmit = true">
                  {{ scheduledSubmitDate ? "Schedule Order": "Submit Order"}}
                </v-btn>
                <v-btn
                  class="ml-4"
                  :disabled="!order.id || loading"
                  outlined
                  @click.stop="confirmDelete = true">
                  Clear
                </v-btn>
              </v-row>
            </v-row>        
          </template>
        </v-navigation-drawer>
      </v-container>
    </v-card>
    <SubmitOrderDialog
      v-if="confirmSubmit"
      v-model="confirmSubmit"
      :submitting="submitting"
      @cancel="cancelSubmit" 
      @confirmSubmit="submitOrder"/>
    <ScheduleOrderDialog
      v-if="confirmSchedule"
      v-model="confirmSchedule"
      :submitting="submitting"
      :scheduledSubmitDate="scheduledSubmitDate"
      @cancel="cancelSchedule" 
      @confirmSchedule="scheduleOrder"/>
    <ConfirmDialog
      v-if="confirmDelete"
      v-model="confirmDelete"
      title="Clear Order"
      text='Clear the current order? This will clear all items.'
      @cancel="cancelDelete"
      @confirm="clearOrder">
    </ConfirmDialog>
  </v-container>
</template>
<script>
// API
import ProductOrder from '@/api/product-order'
// Components
const OrderItemsTable = () => import('@/components/items/OrderItemsTable.vue')
import ItemSearchTable from '@/components/product-orders/ItemSearchTable.vue'
import ItemSearchFilters from '@/components/product-orders/ItemSearchFilters.vue'
const ConfirmDialog = () => import('@/components/shared/ConfirmDialog.vue')
const SubmitOrderDialog = () => import('@/components/products/SubmitOrderDialog.vue')
const ScheduleOrderDialog = () => import('@/components/products/ScheduleOrderDialog.vue')
// Mixins
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
import { debounce } from 'lodash'

export default {
  name: 'OrderEntryView',
  data() {
    return {
      cartOpen: false,
      order: {},
      orderItems: [],
      loading: true,
      loadingFilters: false,
      submitting: false,
      note: null,
      storeId: null,
      confirmSubmit: false,
      confirmSchedule: false,
      confirmDelete: false,
      searchParams: {},
      date_picker: false,
      scheduledSubmitDate: null,
    }
  },
  mixins: [userAccess, displayAlert, utils],
  watch: {
    storeId(newValue) {
      if (newValue) {
        sessionStorage.setItem('store_id', newValue)
      } else {
        sessionStorage.removeItem('store_id')
      }
    },
    async storeSources(newValue) {
      if (newValue && newValue.length > 0) {
        this.loadingFilters = true
        await Promise.all([
          this.$store.dispatch('getDepartments', { sources: newValue, is_retailer: true }),
          this.$store.dispatch('getCategories', { sources: newValue, is_retailer: true })
        ]).catch(err => {
          this.handleError(err)
        })
        this.loadingFilters = false
      }
    }
  },
  components: { ConfirmDialog, ItemSearchTable, OrderItemsTable, ItemSearchFilters, SubmitOrderDialog, ScheduleOrderDialog },
  async created() {
    this.loading = true
    await Promise.all([
      this.$store.dispatch('getUserRelatedParties'),
      this.$store.dispatch('getProductOrderStatuses'),
      this.$store.dispatch('getProductOrderTypes'),
      this.$store.dispatch('getStores')
    ]).catch(err => {
      this.handleError(err)
    })
    if (window.history.length === 1 && !window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload')) {
     await navigator.clipboard.readText().then(this.handleClipText)
    }
    if (this.stores.length > 0) {
      if (sessionStorage.store_id) {
        this.storeId = sessionStorage.store_id
      } else {
        this.storeId = this.stores[0].id
      }
      await this.getProductOrder()
    }
    if (sessionStorage.order_item_filters) {
      const stored = JSON.parse(sessionStorage.order_item_filters)
      this.searchParams = { ...this.searchParams, ...stored }
    }
    this.loading = false
  },
  computed: {
    cartCount() {
      return this.orderItems?.length || 0
    },
    activeOrder () {
      return Boolean(this.order?.id)
    },
    orderingDisabled () {
      return !this.storeId
    },
    stores() {
      if (this.userStores.length > 0) {
        return this.sortByKey(this.userStores, 'name')
      }
      return []
    },
    selectedStore() {
      return this.stores.find(store => this.storeId === store?.id)
    },
    catalogName() {
      return this.selectedStore?.catalog_name
    },
    storeSources() {
      if (this.selectedStore) {
        let sources = [this.selectedStore.dc_source]
        const alt_dc_sources = this.selectedStore.alt_dc_sources
        if (alt_dc_sources.length > 0) {
          sources = [...new Set(sources.concat(alt_dc_sources))]
        }
        return sources
      }
      return []
    },
    productOrderStatuses() {
      return this.$store.state.productOrderStatuses
    },
    productOrderTypes() {
      return this.$store.state.productOrderTypes
    },
    itemProductOrderTypeId() {
      return this.productOrderTypes && this.productOrderTypes.length ? this.productOrderTypes.find(kv => kv.constant === "ITEM")?.id : null
    },
    inProgressStatusId() {
      return this.productOrderStatuses && this.productOrderStatuses.length ? this.productOrderStatuses.find(kv => kv.constant === "IN_PROGRESS")?.id : null
    },
    submittedStatusId() {
      return this.productOrderStatuses && this.productOrderStatuses.length ? this.productOrderStatuses.find(kv => kv.constant === "SUBMITTED")?.id : null
    },
    scheduledStatusId() {
      return this.productOrderStatuses && this.productOrderStatuses.length ? this.productOrderStatuses.find(kv => kv.constant === "SCHEDULED")?.id : null
    },
    totalCost() {
      let finalTotal = 0
      if (this.orderItems?.length > 0) {
        finalTotal = this.orderItems.reduce((total, item) => {
          return total + (item.cost * item.quantity)
        }, 0)
      }
      return this.$config.formatCurrency(finalTotal)
    },
    showActiveAllowances () {
      return this.selectedStore?.attributes.STORE_PRICE_LIST.endsWith('PMC')
    }
  },
  methods: {
    handleChangedStore() {
      if (sessionStorage.order_item_filters) {
        sessionStorage.removeItem('order_item_filters')
      }
      this.resetOrder()
      return (this.page > 1) ? this.page = 1 : this.getProductOrder()
    },
    handleNoteInput: debounce(function() {
      this.updateProductOrder()
    }, 400),
    async getProductOrder() {
      if (this.storeId) {
        try {
          const params = { product_order_type_id: this.itemProductOrderTypeId }
          const { data } = await ProductOrder.getProductOrder(this.storeId, params)
          if (data) {
            this.order = data
            this.note = data.note ? data.note : ''
            await this.getProductOrderItems()
          }
        } catch (e) {
          this.handleError(e)
        }
      }
    },
    async createProductOrder() {
      try {
        const orderPayload = {
          party_id: this.storeId,
          product_event_id: null,
          product_order_type_id: this.itemProductOrderTypeId,
          product_order_status_id: this.inProgressStatusId,
          note: this.note
        }
        const productOrderRes = await ProductOrder.createProductOrder(orderPayload)
        this.order.id = productOrderRes?.data?.uuid
        await this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      }
    },
    async getProductOrderItems() {
      try {
        const params = { 
          product_order_type: 'ITEM', 
          catalog_name: this.selectedStore.catalog_name, 
          offset: 0, 
          limit: 500 
        }
        const { data } = await ProductOrder.getProductOrderItems(params, this.order.id)
        const sorted = data.sort((a, b) => Date.parse(b.audit.created_on) - Date.parse(a.audit.created_on))
        this.orderItems = sorted
      } catch (err) {
        this.handleError(err)
      }
    },
    async updateProductOrder() {
      this.loading = true
      try {
        if (!this.order?.id) {
          await this.createProductOrder()
        } else {
          const params = {
            party_id: this.storeId,
            product_event_id: null,
            product_order_type_id: this.itemProductOrderTypeId,
            product_order_status_id: this.inProgressStatusId,
            note: this.note
          }
          await ProductOrder.updateProductOrder(params, this.order.id, 'ITEM', this.selectedStore.catalog_name)
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    async addItem(item) {
      this.loading = true
      try {
        if (!this.order?.id) {
          await this.createProductOrder()
        }
        const productOrderItem = { 
          product_order_id: this.order.id, 
          source: item.source, 
          item_id: item.item_id,
          upc: item.upc, 
          quantity: 1
        }
        await ProductOrder.createProductOrderItem(productOrderItem)
        await this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    async removeItem(item) {
      this.loading = true
      try {
        await ProductOrder.deleteProductOrderItem(item.id)
        await this.getProductOrder()
        if (this.order?.id && this.orderItems.length === 0) {
          await this.clearOrder()
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    async updateItem(item) {
      this.loading = true
      try {
        const productOrderItem = { 
          product_order_id: this.order.id, 
          source: item.source, 
          item_id: item.item_id,
          upc: item.upc, 
          quantity: Number(item.quantity)
        }
        await ProductOrder.updateProductOrderItem(item.id, productOrderItem)
        await this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    async submitOrder() {
      this.submitting = true
      try {
        const params = {
          party_id: this.storeId,
          product_event_id: null, 
          product_order_type_id: this.itemProductOrderTypeId,
          product_order_status_id: this.submittedStatusId,
          processed: false,
          note: this.note
        }
        await ProductOrder.updateProductOrder(params, this.order.id, this.selectedStore.catalog_name)
        this.confirmSubmit = false
        this.resetOrder()
        this.emitAlert(true, 'success', 'Order Submitted', [], true)
        await this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.submitting = false
        if (this.confirmSubmit) this.confirmSubmit = false
      }
    },
    async scheduleOrder() {
      this.submitting = true
      try {
        const params = {
          party_id: this.storeId,
          product_event_id: null, 
          product_order_type_id: this.itemProductOrderTypeId,
          product_order_status_id: this.scheduledStatusId,
          scheduled_submit_date: this.scheduledSubmitDate,
          processed: false,
          note: this.note
        }
        await ProductOrder.updateProductOrder(params, this.order.id, this.selectedStore.catalog_name)
        this.confirmSchedule = false
        this.resetOrder()
        this.emitAlert(true, 'success', 'Order Scheduled', [], true)
        await this.getProductOrder()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.submitting = false
        if (this.confirmSchedule) this.confirmSchedule = false
      }
    },
    async clearOrder() {
      this.confirmDelete = false
      this.loading = true
      try {
        if (this.orderItems.length > 0) {
          const promises = this.orderItems.map((productOrderItem) => {
            return ProductOrder.deleteProductOrderItem(productOrderItem.id)
          })
          const { rejected } = await this.getAllSettled(promises)
          if (rejected?.length > 0) throw rejected
        }
        await ProductOrder.deleteProductOrder(this.order.id)
        this.resetOrder()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    resetOrder() {
      this.searchParams = {}
      this.order = {}
      this.orderItems = []
      this.note = null
    },
    cancelSubmit() {
      this.confirmSubmit = false
    },
    cancelSchedule() {
      this.confirmSchedule = false
    },
    cancelDelete() {
      this.confirmDelete = false
    },
    handleFilterInput (newValue) {
      this.searchParams = newValue
    },
    handleClipText(clipText) {
      if (clipText.split("_")[0] != "") {
        sessionStorage.setItem('store_id', clipText.split("_")[0])
      }
      sessionStorage.setItem('order_item_filters', JSON.stringify({"item_id": clipText.split("_")[1]}))
    }
  }
}
</script>
<style scoped>
.v-application--is-ltr .v-data-footer__pagination {
  margin-left: auto;
}
.qty-controls {
  display: grid; 
  width: 100%; 
  max-width: 100px; 
  grid-template-columns: auto 40px auto;
}
</style>