<template>
  <v-dialog :value="value" persistent width="700" @click:outside="close()">
    <v-card>
      <v-card-title class="pl-5 pr-2 pt-4 pb-2">
        <div class="title h6">
          Active Allowances - {{ selectedItem.description }}
        </div>
        <br/>
        <v-spacer></v-spacer>
        <v-card-subtitle class="pa-0 ma-0">
          Item ID: {{ selectedItem.item_id }}
        </v-card-subtitle>
        <v-btn icon @click="close()">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-container fluid class="pa-0">
        <v-data-table
          :headers="headers"
          :items="formattedAllowances"
          no-data-text="No approved allowances"
          dense
          :height="tableHeight"
          fixed-header
          disable-pagination
          :footer-props="{'items-per-page-options': [-1]}"
          :items-per-page="-1">
        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { utils } from '@/mixins/utils'
import { displayAlert } from '@/mixins/alert'
export default {
  name: 'ActiveAllowancesModal',
  mixins: [displayHelpers, displayAlert, utils],
  props: [
    'value',
    'selectedItem'
  ],
  data() {
    return {
      headerProps: {
        sortable: false,
        class: 'accent white--text'
      },
      baseHeaders: [
        { text: 'Promo Name', value: 'promo_name', width: '30%' },
        { text: 'Promo Type', value: 'billing_mode' },
        { text: 'Start Date', value: 'start_dt' },
        { text: 'End Date', value: 'end_dt' },
        { text: 'Off Invoice', value: 'off_invoice_allowance' }
      ],
      allowanceFields: [
        'ad_scan', 'edlc_scan', 'tpr_scan',
        'amap_allowance', 'early_buy_allowance',
        'ad_billback', 'edlc_billback', 'tpr_billback',
        'off_invoice_allowance'
      ]
    }
  },
  computed: {
    activeAllowances () {
      return this.selectedItem.active_allowances
    },
    formattedAllowances () {
      return this.activeAllowances.map(a => this.formatAllowance(a))
    },
    headers() {
      return this.baseHeaders.map(header => {
        return { ...this.headerProps, ...header }
      })
    },
    tableHeight () {
      return this.currentAllowances?.length >= 12 ? '384px' : undefined
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    formatAllowance(allowance) {
      for (const field of this.allowanceFields) {
        const value = allowance[field]
        allowance[field] = (value > 0) ? `$${this.$config.formatCurrency(value)}` : '-'
      }
      return {
        ...allowance,
        start_dt: this.$config.formatDate(allowance.start_dt),
        end_dt: this.$config.formatDate(allowance.end_dt)
      }
    }
  }
}
</script>
<style>
.header {
  color: white;
  background: #385F73;
}
.title {
  width: 73%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
  
