<template>
  <v-dialog :value="value" persistent width="700" @click:outside="closeDialog()">
    <v-card color="fill">
      <v-container>
        <v-row class="my-2">
          <v-col cols="11" class="ma-0 pa-0" align="start">
            <v-card-title class="my-0 py-0 pt-0 heading-6">
              <div class="text-no-wrap">
                Product Order: {{ selectedProductOrder.product_order_number }}
              </div>
            </v-card-title>
            <v-card-subtitle class="my-0 py-0 pt-2" v-if="selectedProductOrder.product_event_name">
              {{ 'Event: ' + selectedProductOrder.product_event_name }}
            </v-card-subtitle>
          </v-col>
          <v-col class="pa-0 ma-0 pr-4" align="end">
            <v-btn icon @click="closeDialog()">
              <v-icon dense>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <template v-if="loading">
          <v-progress-linear indeterminate color="primary" height="6" />
        </template>
        <template v-else>
          <v-row dense align="center" class="header mx-1 py-1">
              <v-col align="start" class="pl-2 ml-0">
                Item
              </v-col>
              <v-col cols="2">
                Cost
              </v-col>
              <v-col cols="auto" class="pl-0 mr-2">
                Qty
              </v-col>
          </v-row>
          <v-row dense class="mx-1 pb-1">
            <v-list v-if="orderItems.length > 0" dense class="pt-0" width="100%" style="max-height: 60vh; overflow-y: auto;">
              <template v-for="(item, index) in orderItems">
                <div :key="item.item_id">
                  <v-list-item class="ma-0 pl-2 py-1">
                    <v-row dense align="center" class="pl-0 ml-0">
                      <v-col align="start" class="pl-0 ml-0">
                        #{{ item.item_id + ' - ' }} {{ item.description }}
                      </v-col>
                      <v-col cols="2">
                        {{ item.cost ? '$' + $config.formatCurrency(item.cost) : '-' }}
                      </v-col>
                      <v-col cols="auto" class="pl-0 mr-2">
                        {{ item.quantity }}
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-divider 
                    v-if="index < orderItems.length - 1"
                  />
                </div>
              </template>
            </v-list>
            <v-row class="my-4" v-else dense>
              <v-col align="center">
                <p class="font-weight-light text-no-wrap">
                  This product order is empty.
                </p>
              </v-col>
            </v-row>
          </v-row>
          <div v-if="selectedProductOrder.note">
            <v-row dense align="center" class="py-2">
              <v-col align="start">
                <p class="my-auto ml-1" style="font-size: 14px">
                  Note: {{ selectedProductOrder.note }}
                </p>
              </v-col>
            </v-row>
          </div>
          <div style="position: relative">
            <v-row dense align="center" class="py-2">
              <v-col align="center">
                <p class="font-weight-light text-no-wrap my-auto" style="font-size: 14px">
                  Updated by {{ selectedProductOrder.audit.updated_by }} on {{ $config.formatTimestamp(selectedProductOrder.audit.updated_on) }}
                </p>
              </v-col>
              <v-col align="end">
                <p class="font-weight-medium text-no-wrap my-auto mr-16">
                  Total: ${{ totalCost }}
                </p>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { displayHelpers } from '@/mixins/display-helpers'
import { utils } from '@/mixins/utils'
import { displayAlert } from '@/mixins/alert'
import { userAccess } from '@/mixins/user-access'
import ProductOrder from '@/api/product-order'

export default {
  name: 'OrderView',
  mixins: [displayHelpers, displayAlert, utils, userAccess],
  props: [
    'value',
    'selectedStore',
    'selectedProductOrder'
  ],
  data() {
    return {
      loading: false,
      orderItems: [],
      productEvent: {},
      readonly: true
    }
  },
  async created() {
    this.loading = true
    if (this.selectedProductOrder) {
      this.productOrder = { ...this.selectedProductOrder }
    }
    await this.getProductOrderItems()
    this.loading = false
  },
  computed: {
    totalCost() {
      let finalTotal = 0
      if (this.orderItems?.length > 0) {
        finalTotal = this.orderItems.reduce((total, item) => {
          return total + (item.cost * item.quantity)
        }, 0)
      }
      return this.$config.formatCurrency(finalTotal)
    },
    productOrderTypes() {
      return this.$store.state.productOrderTypes
    },
    itemsId() {
      if (this.productOrderTypes && this.productOrderTypes.length) {
        return this.productOrderTypes.find(kv => kv.constant === "ITEM")?.id
      } else {
        return null
      }
    },
    productOrderStore() {
      if (this.selectedProductOrder?.party_id && this.userStores.length > 0) {
        return this.userStores.find(s => s.id === this.selectedProductOrder.party_id)
      }
      return null
    },
    storePriceKey() {
      return this.productOrderStore?.price_key || null
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeOrderView')
    },
    async getProductOrderItems() {
      let orderItems = []
      try {
        const params = { catalog_name: this.productOrderStore.catalog_name, offset: 0, limit: 500 }
        const { data } = await ProductOrder.getProductOrderItems(params, this.productOrder.id)
        if (data?.length > 0) {
          orderItems = data.map(product => this.setProductPrices(product, this.storePriceKey))
        }
        this.orderItems = orderItems
      } catch (err) {
        this.handleError(err)
      }
    }
  }
}
</script>
<style>
.header {
  color: white;
  background: #385F73;
}
</style>
